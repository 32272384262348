.solution_img {
  width: 100%;
  max-width: 700px;
  border-radius: 20px;
  box-shadow: rgba(225, 241, 255, 0.48) 6px 2px 16px 0px,
    rgba(57, 36, 36, 0.8) -6px -2px 16px 0px;
}
.solution-offered {
  display: flex;
  gap: 40px;
}
.solution-text-area {
  position: relative;
  width: 100%;
}
/* .solution-text{
    position: absolute;
} */
.moderl-sec {
  padding: 30px;
  margin: 10px 0px;
  border-radius: 15px;
  background-color: #ffffff;
}
.solution-header {
  margin-bottom: 20px;
}
.solution-child-area{
    position: relative;
}
@media screen and (max-width: 700px) {
    .solution-offered{
      display: flex;
      flex-direction: column;
    }
}
