/* our project content container */

.project-banner{
    display: flex;
    padding-top: 5vh;
    padding-bottom: 5vh;
}
.project-ban-left-sec{
    width: 50%;
    position: relative;  
    
}
.project-ban-right-sec{
    width: 50%;
}
.project-ban-left-sec .sp_title{
    font-size: 3rem;
    font-weight: 600;
    position: absolute;  
    top: 50%;  
    left: 50%;  
    transform: translate(-50%, -50%); 
}
.banner-child-sec{
    display: flex;
}
.main-wrapper-one{
   margin-top: 10px;
   margin-right: 10px;
}
 .main-wrapper-two{
    display: flex;
    gap: 80px;
 }

@media screen and (max-width: 650px) {
    .main-wrapper-two{
        flex-direction: column;
    }
    .project-ban-right-sec img{
        display: none;
    }
    .project-ban-left-sec .sp_title{
        font-size: 2rem;
        font-weight: 600;
        line-height: 50px;
        display:contents;
    }

}