.scroll-container {
    width: 100vw;
    height: 100vh;
    -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
  }
.scroll-container::-webkit-scrollbar{
  display: none;
}
.scrollbar-thumb{
  width: 4px!important;
}
.scrollbar-track-y{
  width: 4px!important;
}