.contact_section{
    background-color: white;
} 
.c_title_box{
    background-color: #f5f5f5;
    padding-top: 150px;
    padding-bottom: 250px;
    padding-left: 24px;
    padding-right: 24px;
}
.c_title{
    max-width: 1235px;
    margin: 0 auto;
    width: 100%;
    font-size: clamp(3rem, 2.7292rem + 1.4444vw, 3.8125rem);
    line-height: 1.25;
    letter-spacing: -.03em;
}
.c__banner .c_img{
    width: 100%;
    height: 500px;
    margin-top: -200px;
}
.c__banner .c_img img{
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.contact_section .contact_form{
    max-width: 768px;
    width: 100%;
    margin: 90px auto;
    margin-bottom: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.contact_form .cf_title{
    width: 100%;
    font-style: italic;
    font-family: eskorte-latin,Georgia,Garamond,serif;
    font-size: 2.4375rem;
    font-weight: 500;
    margin-bottom: 30px;
    line-height: 1.25;
}
.cf_para{
    width: 100%;
    font-size: clamp(1rem, 0.9167rem + 1vw, 1.5625rem);
    margin-bottom: 30px;
}
.cf_small_para{
    width: 100%;
    font-size: .8rem;
    line-height: 1.5;
    margin-bottom: 44px;
}

.contact_form form{
    width: 100%;
}

.cf_input_field{
    margin-bottom: 25px;
}

.cf_label{
    font-size: .8rem;
    font-weight: 700;
    line-height: 1.25;
    color: #111921!important;
    text-transform: uppercase;
    letter-spacing: .02em;
    margin-top: 0!important;
    -ms-flex-item-align: start;
    align-self: flex-start;
}

.cf_field{
    position: relative;
}

.cf_field input {
    width: 100%;
    height: 3.75rem;
    font-size: 17px;
    font-weight: 400;
    background-color: #f5f5f5;
    border: 1px solid #c7c7c7;
    padding: 0 15px;
    outline: none;
}
.cf_field textarea{
    width: 100%;
    background-color: #f5f5f5;
    font-family: inherit;
    font-size: inherit;
    letter-spacing: normal;
    line-height: 1.5;
    padding: 6px 8px;
    border: 1px solid #c7c7c7;
    resize: none;
    outline: none;
}

.cf_field::before {
    content: '';
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 4px 0 4px 6.9px;
    border-color: transparent transparent transparent #00aeef;
    position: absolute;
    top: 50%;
    left: 2px;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    opacity: 0;
    pointer-events: none;

}

.cf_field:focus-within::before {
    opacity: 1;
} 

.cf_field input:focus{
    border-color: #00aeef;
    border-width: 1px 1px 1px 3px;
}
.cf_field textarea:focus{
    border-color: #00aeef;
    border-width: 1px 1px 1px 3px;
}

.cf_btn_field{
    display: flex;
    justify-content: flex-end;
    margin: 40px 0;
    padding-bottom: 60px;
}

.cf_btn_field .cf_btn{
    position: relative;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    padding: 0 1.5rem;
    min-width: 10rem;
    height: 3.75rem;
    font-weight: 700;
    font-size: 1.25rem;
    line-height: 1.25;
    text-align: center;
    text-decoration: none;
    background-color: #009bd6;
    color: #fff;
    border: 0;
    outline: 0;
    cursor: pointer;
}
.hidden_field{
    visibility: hidden;
    width: 0px;
    height: 0px;
}
@media only screen and (max-width: 600px) {
    .cf_btn_field{
        justify-content: center;
    }
    .cf_btn_field .cf_btn{
        width: 100%;
    }
}
