/* about banner */
.about_banner_contain .ab_ban_img {
  height: 80vh;
}

.ab_ban_img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

/* about content container */

.about_banner_contain .ab__content_sec {
  background-color: #111921;
  margin-left: 300px;
  position: relative;
  display: flex;
}

.ab__content_sec .ab_cont_1 {
  background-color: white;
  width: 75%;
  height: max-content;
  margin-top: -150px;
  margin-left: -300px;
  padding: 50px 70px;
  box-shadow: rgba(0, 0, 0, 0.2) 0px 33px 56px -7px
}

.ab__content_sec .ab_cont_2 {
  color: white;
  width: 350px;
  position: relative;
  margin: 100px 100px 20px 100px;
}

.ab__content_sec .ab_cont_1 .ab_title {
  font-size: 1.5625rem;
  margin-bottom: 32px;
}

.ab__content_sec .ab_cont_1 .ab_sub_para {
  font-size: 20px;
  margin-bottom: 20px;
}

.ab__content_sec .ab_cont_1 .ab_para,
.ab__content_sec .ab_cont_2 .ab_para {
  font-size: 16px;
  font-weight: 400;
  margin-bottom: 25px;
}

.ab_cont_2 ul li.ab_para {
  margin-bottom: 16px !important;
}

.ab__content_sec .ab_cont_2 .ab_title {
  color: white;
  font-weight: 800;
  line-height: 1.25;
  border-top: 1px solid #979899;
  font-size: 1.5625rem;
  padding-top: 10px;
  margin-bottom: 10px;
}

/* About states Secttion */
.about_state {
  margin: 100px 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  border: 1px solid black;
}

.about_state .as_state_box {
  width: 100%;
  height: 100%;
  flex: 1;
  padding: 30px 40px 50px 40px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.about_state .as_state_box:nth-child(2) {
  border-right: 1px solid black;
  border-left: 1px solid black;
}

.as_state_box .as_count {
  font-size: 4.8125rem;
  font-style: italic;
  text-align: center;
  margin-bottom: 0;
  color: #f5f5f5;
  text-shadow: -1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000, 1px 1px 0 #000;
}

.as_state_box .as_text {
  width: 100%;
  text-align: center;
  font-size: 1.25rem;
  color: #000;
  max-width: 15ch;
}



/* About drag slider */
.ad__container .ad__heading {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  margin-top: 100px;
  margin-bottom: 50px;
}

.ad__heading .ad_title {
  font-size: clamp(1.9375rem, 1.7708rem + 0.8889vw, 2.4375rem);
  padding-bottom: 20px;
  margin-bottom: 45px;
  border-bottom: 1px solid #979899;
  font-weight: 600;
  line-height: 1.25;
}

.ad__heading .ad_para {
  font-size: 1rem;
  margin-bottom: 25px;
  max-width: 60ch;
}

.ad__heading .link_button {
  font-weight: 400 !important;
}

/* width */
.ad__ItemsContainer::-webkit-scrollbar {
  height: 4px;
}

/* Track */
.ad__ItemsContainer::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  margin: 0 20vw;
}

/* Handle */
.ad__ItemsContainer::-webkit-scrollbar-thumb {
  background: #313131;
}

/* Handle on hover */
.ad__ItemsContainer::-webkit-scrollbar-thumb:hover {
  background: #3d3d3d;
}

.ad__MainContainer {
  margin-bottom: 100px;
}

.ad__ItemsContainer {
  width: 100%;
  height: 620px;
  padding: 50px;
  white-space: nowrap;
  overflow-x: scroll;
  overflow-y: hidden;
  cursor: grab;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
  display: flex;
  justify-content: center;
}

.ad__item {
  width: 320px;
  position: relative;
  display: inline-flex;
  background-color: white;
  transition: .3s ease-in-out;
}

.ad_item_m:first-child{
  margin-left: 100px;
}

.ad_item_m:last-child{
  margin-right: 100px;
}

.ad__item .ad__item_contain {
  width: 100%;
  border-width: 1px 0px 1px 1px;
  border-style: solid;
  border-color: black;
  padding: 20px;
  display: flex;
  flex-direction: column;
}

.ad__item:last-child .ad__item_contain {
  border-width: 1px 1px 1px 1px;
}

.ad__item .ad__item_contain::before {
  content: '';
  bottom: 0;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  background-color: #fff;
  pointer-events: none;
  z-index: -1;
  -webkit-transition: .3s ease-in-out;
  transition: .3s ease-in-out;
}

.ad__item:hover {
  transform: translateY(-9px);
  z-index: 1;
  box-shadow: rgba(17, 17, 26, 0.1) 0px 8px 24px, rgba(17, 17, 26, 0.1) 0px 16px 56px, rgba(17, 17, 26, 0.1) 0px 24px 80px;
}

.ad__item:hover .ad__item_contain {
  margin-bottom: 10px;
  border-width: 1px 1px 1px 1px;
}

.ad__item:hover .ad__item_contain::before {
  content: '';
  bottom: 0px;
  left: -10px;
  position: absolute;
  right: -10px;
  top: -10px;
  background-color: #fff;
  pointer-events: none;
  z-index: -1;
  -webkit-transition: .3s ease-in-out;
  transition: .3s ease-in-out;
}

.ad__img {
  width: 100%;
  height: 300px;
}

.ad__img img {
  width: 80%;
  height: auto;
  user-drag: none;
  -webkit-user-drag: none;
  -moz-user-select: none;
}

.ad__content {
  padding-top: 5px;
  display: flex;
  flex-direction: column;
}

.ad__content .ad_name {
  font-size: 18px;
  font-weight: 700;
  line-height: 1.25;
  margin-bottom: 2px;
  color: #000;
  max-width: 100%;
  overflow-x: hidden;
  margin: 5px 0px;
  text-wrap: wrap;
}

.ad__content .ad_detail_1 {
  text-transform: uppercase;
  color: #979899;
  font-size: .8rem;
  font-weight: 700;
  line-height: 1.25;
  letter-spacing: .02em;
  margin-bottom: 0;
  max-width: 100%;
}

.ad__content .ad_detail_2 {
  font-style: italic;
  line-height: 1.25;
  margin-top: 6.25%;
  margin-bottom: 15%;
  max-width: 100%;
  font-size: 16px;
}

.ad__content .ad_detail_3 {
  font-size: .8rem;
  margin-top: 45px;
  padding-bottom: 8px;
  max-width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.ad__activeClass {
  cursor: grabbing;
}


/* Logo Group section */
.logo_group_container {
  padding: 50px 0;
}

.logo_group_container .lg__header {
  margin: 0px;
}

.lg__header .lg_title {
  font-size: clamp(1.9375rem, 1.7708rem + 0.8889vw, 2.4375rem);
  padding-bottom: 20px;
  margin-bottom: 45px;
  border-bottom: 1px solid #979899;
  font-weight: 600;
  line-height: 1.25;
}

.lg_para {
  width: 100%;
}

.lg_para p {
  font-size: 1rem;
  margin-left: auto;
  max-width: 70%;
}

.lg__img_box {
  margin-top: 50px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.lg__img_box .lg_img {
  width: 20%;
  display: flex;
  justify-content: center;
  margin: 15px 0;
}

.lg__img_box .lg_img:hover img {
  filter: grayscale(0);
}

.lg__img_box .lg_img img {
  width: 50%;
  height: auto;
  filter: grayscale(100%);
}

/* About Section Card Style */

.about_card_sec{
  padding: 0 5vw;
}
.acs__container {
  position: relative;
  width: 100%;
  margin: 100px 0;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid black;
  background-color: #fff;
}
.acs__container::before {
  content: '';
  bottom: 0px;
  left: 0px;
  position: absolute;
  right: 0px;
  top: 0px;
  background-color: #fff;
  pointer-events: none;
  z-index: -1;
  -webkit-transition: .3s ease-in-out;
  transition: .3s ease-in-out;
}

.acs__container:hover::before {
  content: '';
  bottom: -10px;
  left: -10px;
  position: absolute;
  right: -10px;
  top: -10px;
  background-color: #fff;
  pointer-events: none;
  z-index: -1;
  -webkit-transition: .3s ease-in-out;
  transition: .3s ease-in-out;
  box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;
}

.acs__container .acs_card {
  position: relative;
  width: 30%;
  border-right: 1px solid black;
}

.acs__container .acs_arrow {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  color: #fff;
  font-size: 12rem;
  text-align: center;
  font-weight: 800;
  z-index: -1;
  transition: 0.5s;
  display: flex;
  align-items: center;
  justify-content: center;
}

.acs__container:hover .acs_arrow {
  z-index: 2;
}

.acs__container .acs_card img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  filter: blur(0);
  transition: 0.5s;
}

.acs__container:hover img {
  filter: blur(4px);
}

.acs__container .acs_text {
  width: 70%;
  padding: 0 30px;
}

.acs__container .acs_text h1 {
  font-size: 1.5625rem;
  font-weight: 700;
  line-height: 1.25;
  margin-bottom: 1.0625rem;
}

.acs__container .acs_text p {
  font-size: 1.25rem;
  margin-bottom: 0;
}


.image-container{
  display: flex;
}
.ad__container {
  max-width: 1400px; /* Adjust container width */
  margin: 0 auto; /* Centers the container */
  padding: 20px;
}

.image-container {
  display: grid;
  grid-template-columns: repeat(2, 1fr); /* Two equal columns */
  gap:30px; /* Adds space between images */
  align-items: center;
}

.img-one {
  width: 100%; /* Ensures images take up full column width */
  display: flex;
  justify-content: center;
}
.ad_title {
  font-size: clamp(1.9375rem, 1.7708rem + 0.8889vw, 2.4375rem);
  padding-bottom: 20px;
  margin-bottom: 45px;
  border-bottom: 1px solid #979899;
  font-weight: 600;
  line-height: 1.25;
}
.breaak{
  margin-top: 30px;
}


@media (min-width: 1280px) {
  .css-cnms5y-MuiContainer-root {
  max-width:100%;
  }
}


@media only screen and (max-width: 1200px) {
  .about_state {
    margin: 100px 5vw;
  }

  .ad__container .ad__heading {
    margin: 50px 5vw;
  }

  .logo_group_container .lg__header {
    margin: 0 5vw;
  }

}

@media only screen and (max-width: 1000px) {
  .about_banner_contain .ab__content_sec {
    margin-left: 0px;
    flex-direction: column;
  }

  .about_banner_contain .ab__content_sec {
    padding-right: 90px;
  }

  .ab__content_sec .ab_cont_1 {
    width: 100%;
    margin-left: 0;
  }

  .ab__content_sec .ab_cont_2 {
    margin: 0;
    margin-left: 50px;
    padding: 50px 0;
  }

  /* about states */
  .about_state {
    flex-direction: column;
  }

  .about_state .as_state_box:nth-child(2) {
    border-right: 0;
    border-left: 0;
    border-top: 1px solid black;
    border-bottom: 1px solid black;
  }

  /* Logo Group Style */
  .lg_para p {
    font-size: 1rem;
    margin-left: 0;
    max-width: 100%;
  }

  /* About Card Section */
  .acs__container {
    flex-direction: column;
  }

  .acs__container .acs_card {
    width: 100%;
    border-right: 0px;
    border-bottom: 1px solid black;
  }

  .acs__container .acs_text {
    width: 100%;
    padding: 30px;
  }

  /* about drag */
  .ad__container .ad__heading {
    margin-bottom: 0px;
  }
  .ad_item_m:first-child{
    margin-left: 0px;
  }
  
  .ad_item_m:last-child{
    margin-right: 0px;
  }
}

@media only screen and (max-width: 768px) {
  .about_banner_contain .ab__content_sec {
    padding-right: 50px;
  }

  .ab__content_sec .ab_cont_1 .ab_title {
    font-size: 1.5625rem;
  }

  .ab__content_sec .ab_cont_1 .ab_sub_para {
    font-size: 16px;
  }

  .ab__content_sec .ab_cont_1 .ab_para {
    font-size: 14px;
  }

  /* about states */
  .ad__ItemsContainer{
    display: block;
  }
  .image-container{
    display: block;
  }
  .img-one{
    margin-top:40px;
  }
}

@media only screen and (max-width: 600px) {
  .lg__img_box .lg_img {
    width: 30%;
  }
}

@media only screen and (max-width: 425px) {
  .lg__img_box .lg_img img {
    width: 70%;
  }
}